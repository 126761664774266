import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import parse from "html-react-parser"
import React from "react"
import FingerPrintLeft from "../components/FingerPrint/FingerPrintLeft"
import FingerPrintRight from "../components/FingerPrint/FingerPrintRight"
import Footer from "../components/Footer/footer"
import Header from "../components/header"
import Layout from "../components/layout.js"
import Seo from "../components/seo"
import { StoreContext } from "../store"
import isScrolling from "../utilities/isScrolling"

const CaseStudy = ({ data, location }) => {
  const { title, content, caseStudyLogo, caseStudyProfile, featuredImage } =
    data.wpCaseStudy

  const { viewPortWidth } = React.useContext(StoreContext)

  let logoImg = ""
  let logoImgAlt = ""
  let featuredImg = ""
  let featuredBgImg = ""
  let featuredImgAlt = ""
  let caseStudiesFooterBlock = ""
  let caseStudiesFooterBlockFieldName
  let caseStudiesFooterBlockMarkup = ""

  if (featuredImage.node) {
    featuredImg = getImage(
      featuredImage.node.localFile.childImageSharp.gatsbyImageData
    )
    featuredBgImg = convertToBgImage(featuredImg)
    featuredImgAlt = featuredImage.node.altText
  }

  if (caseStudyLogo.caseStudyLogo) {
    logoImg = getImage(
      caseStudyLogo.caseStudyLogo.localFile.childImageSharp.gatsbyImageData
    )
    logoImgAlt = caseStudyLogo.caseStudyLogo.altText
  }

  const caseStudyStats = caseStudyProfile.caseStudyStats

  // Featured Footer Item
  if (
    data.allWp.edges[0].node.overviewCaseStudies.overviewCaseStudies
      .caseStudiesFooterContent !== null
  ) {
    caseStudiesFooterBlockFieldName =
      data.allWp.edges[0].node.overviewCaseStudies.overviewCaseStudies
        .fieldGroupName
    caseStudiesFooterBlock =
      data.allWp.edges[0].node.overviewCaseStudies.overviewCaseStudies
        .caseStudiesFooterContent.content
    caseStudiesFooterBlockMarkup = () => {
      if (caseStudiesFooterBlock) {
        return { __html: `${caseStudiesFooterBlock}` }
      }
    }
  }

  React.useEffect(() => {
    isScrolling()
  })

  return (
    <Layout>
      <Seo pageData={data.wpCaseStudy} location={location} />

      <div className="patterns-case-study-template-default single single-patterns-case-study wp-embed-responsive js">
        <div className="Page">
          <Header siteTitle={`GoodHire`} viewPortWidth={viewPortWidth} />

          <div className="Page-content">
            <section className="Body">
              <div className="Wrap">
                <div className="Grid with-12 has-xSmallGaps CaseStudy-wrapper">
                  <div className="is-12">
                    <p className="CaseStudy-breadcrumb">
                      <Link
                        to="/case-studies/"
                        target="_self"
                        className="BreadcrumbParent"
                      >
                        Case Studies
                      </Link>
                      <span className="BreadcrumbChild">{title}</span>
                    </p>
                  </div>

                  <div className="is-12">
                    <FingerPrintLeft />
                    <FingerPrintRight />
                    {/* <div className="CaseStudy-banner"></div> */}

                    <BackgroundImage Tag="div" {...featuredBgImg}>
                      <div className="CaseStudy-banner"></div>
                    </BackgroundImage>
                  </div>

                  <div className="is-4 is-12--small">
                    <aside className="CaseStudy-sidebar">
                      <div className="CaseStudy-sidebar-promo">
                        <div className="CaseStudy-sidebar-promo-logo">
                          <div className="CaseStudy-sidebar-wrapper">
                            <GatsbyImage image={logoImg} alt={logoImgAlt} />
                          </div>
                        </div>
                        <div className="CaseStudy-sidebar-wrapper">
                          {caseStudyStats.map((stat, k) => {
                            const value = stat.value
                            const label = stat.label

                            return (
                              <div
                                key={k}
                                className="CaseStudy-sidebar-promo-stat"
                              >
                                <div className="CaseStudy-sidebar-promo-stat-value">
                                  {parse(value)}
                                </div>
                                {parse(label)}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className="CaseStudy-sidebar-summary">
                        <div className="CaseStudy-sidebar-wrapper">
                          <ul>
                            <li>
                              <b>Industry</b>
                              {caseStudyProfile.caseStudyIndustry}
                            </li>
                            <li>
                              <b>Headquarters</b>
                              {caseStudyProfile.caseStudyHeadquarters}
                            </li>
                            <li>
                              <b>Locations</b>
                              {caseStudyProfile.caseStudyLocations}
                            </li>
                            <li>
                              <b>Employees</b>
                              {caseStudyProfile.caseStudyEmployees}
                            </li>
                            <li>
                              <b>Background Checks</b>
                              {caseStudyProfile.caseStudyBackgroundChecks}
                            </li>
                            <li>
                              <b>Important Screens</b>
                              {caseStudyProfile.caseStudyImportantScreens && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      caseStudyProfile.caseStudyImportantScreens,
                                  }}
                                />
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="CaseStudy-sidebar-download">
                        <div className="CaseStudy-sidebar-wrapper">
                          <a
                            href={
                              caseStudyProfile.caseStudyPdfMedia.localFile
                                .publicURL
                            }
                            target="_blank"
                            rel="noopener"
                          >
                            Download PDF
                          </a>
                        </div>
                      </div>
                    </aside>
                  </div>

                  <div className="is-8 is-12--small">
                    <div className="CaseStudy-content">
                      <div className="Body-content">{parse(content)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          {data.allWp.edges[0].node.overviewCaseStudies.overviewCaseStudies
            .caseStudiesFooterContent !== null && (
            <span
              className={`${caseStudiesFooterBlockFieldName}-footer`}
              dangerouslySetInnerHTML={caseStudiesFooterBlockMarkup()}
            />
          )}

          <Footer />
        </div>
      </div>
    </Layout>
  )
}

export default CaseStudy

export const query = graphql`
  query ($id: String!) {
    wpCaseStudy(id: { eq: $id }) {
      id
      title
      content
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1232, placeholder: BLURRED)
            }
          }
          mediaDetails {
            height
            width
          }
        }
      }
      caseStudyLogo {
        caseStudyLogo {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      caseStudyProfile {
        caseStudyBackgroundChecks
        caseStudyEmployees
        caseStudyHeadquarters
        caseStudyImportantScreens
        caseStudyIndustry
        caseStudyLocations
        caseStudyPdfLinkType
        caseStudyPdfUrl
        caseStudyStats {
          label
          value
        }
        caseStudyPdfMedia {
          id
          localFile {
            publicURL
          }
        }
      }
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphSiteName
        opengraphType
        readingTime
      }
      richSnippetSchemaJson
    }
    allWp {
      edges {
        node {
          overviewCaseStudies {
            overviewCaseStudies {
              fieldGroupName
              featuredCaseStudyTitle
              featuredCaseStudyPost {
                ... on WpCaseStudy {
                  id
                  caseStudyLogo {
                    caseStudyLogo {
                      id
                      localFile {
                        extension
                        publicURL
                        childImageSharp {
                          gatsbyImageData(
                            formats: AUTO
                            placeholder: BLURRED
                            layout: FULL_WIDTH
                          )
                        }
                        publicURL
                        extension
                      }
                      altText
                    }
                  }
                  featuredImage {
                    node {
                      altText
                      localFile {
                        extension
                        publicURL
                        childImageSharp {
                          gatsbyImageData(
                            formats: AUTO
                            placeholder: BLURRED
                            layout: FULL_WIDTH
                          )
                        }
                      }
                    }
                  }
                  slug
                  uri
                  title
                }
              }
              caseStudiesFooterContent {
                ... on WpPage {
                  id
                  content
                }
              }
            }
          }
          overviewFooter {
            ...OverviewFooterQuery
          }
          overviewFooterDescription {
            ...OverviewFooterDescriptionQuery
          }
          overviewSocial {
            ...OverviewSocialQuery
          }
        }
      }
    }
  }
`
